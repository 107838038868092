import {ResetPassword} from '../components/auth/ResetPassword';

// Page de login du site
export const PasswordReset = () => {
    return (
        <div className="Login">
            <ResetPassword />
        </div>
    );
};
