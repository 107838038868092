import LoginForm from '../components/auth/Login';

// Page de login du site
export const Login = () => {
    return (
        <div className="Login">
            <LoginForm />
        </div>
    );
};
