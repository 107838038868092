import { useEffect, useRef, useState } from 'react';

// Formulaire pour que les étudiants de l'utt puissent choisir les rôles qui les intérresseraient pour l'inté
export const Barbecue = () => {

   
    return (
        <>
            <div className='containerChoix'>
                <p>Barbecue</p>
            </div>
        </>
    )
}
